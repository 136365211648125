import axios from './axios';

import { ICard, ICustomer, ITask } from './store/chat/types';
/*
export const loadTasks = (dbUpdate:any, cards:ICard[]) => {
	axios.get("getdata?model=tasks").then((resp)=>{
    if(resp.data.status==="auth") {
			dbUpdate({loggedIn:false});
    }
		else {
			const tc:ITask[]=[]
			//const ncards=[...cards]
			Object.values(resp.data).map((ob:any)=>{
				const card=cards.find((c)=>c.id===ob.card_id)
				if(card) {
					card.tasks=[...card.tasks || [],model2ITask(ob)]
				}
			})
			console.log(cards)
			dbUpdate({cards:[...cards]})
    }
  });

}
*/
export const loadCards = (dbUpdate:any, customers:ICustomer[]) => {
	axios.get("getdata/cards/?model=cards").then((resp)=>{
    if(resp.data.status==="auth") {
			dbUpdate({loggedIn:false});
    }
		else {
			const tc:ICard[]=[]
			Object.values(resp.data).map((ob:any)=>{
				const c:ICard=model2ICard(ob,customers);
				tc.push(c)
			})
			dbUpdate({cards:tc})
			//loadTasks(dbUpdate,tc)
    }
  });
}

export const model2ICard = (ob:any,customers:ICustomer[]):ICard => {
	const c:ICard={
		id:ob.id,
		header:ob.name,
		details:ob.attrs.details,
		amount:ob.attrs.amount,
		stars:ob.attrs.stars,
		time:ob.attrs.time,
		dueDate:ob.attrs.dueDate,
		status:ob.attrs.status,
		//						tasks:ob.attrs.tasks,
		color:ob.attrs.color,
		tasks:ob.tasks.map((tob:any)=>model2ITask(tob)),
	}

	if(ob.customer_id && customers) {
		c.customer=customers.find((c)=>c.id===ob.customer_id)
	}

	return c
}

export const ICard2model = (data:ICard):any => {
	return {
			id:data.id,
			name:data.header,
			customer_id:data.customer && data.customer.id,
			attrs:{
				details:data.details,
				amount:data.amount,
				stars:data.stars,
				time:data.time,
				dueDate:data.dueDate,
				status:data.status,
				color:data.color,
			},

			tasks:(data.tasks || []).map((t)=>ITask2model(t))
	}
}

//--------------------------------------------------------------------------

export const model2ITask = (ob:any):ITask => {
	const c:ITask={
		id:ob.id,
		status:ob.attrs.status,
		time:ob.attrs.time,
		alert:ob.attrs.alert,
		action:ob.attrs.action,
		description:ob.attrs.description,

		contact:ob.attrs.contact,
		phone:ob.attrs.phone,
		email:ob.attrs.email,
		place:ob.attrs.place,
	}

	return c
}

export const ITask2model = (data:ITask):any => {
	return {
			id:data.id,
			attrs:{
				status:data.status,
				time:data.time,
				alert:data.alert,
				action:data.action,
				description:data.description,

				contact:data.contact,
				phone:data.phone,
				email:data.email,
				place:data.place,
			},
	}
}
