import {
  ChatState,
//  SEND_MESSAGE,
//	UPDATE_MESSAGE,
//  DELETE_MESSAGE,
	DB_INITIALIZE,
	DB_UPDATE,
  ChatActionTypes
} from "./types";

//const now=new Date();

export const initialState: ChatState = {
	cards:[],
	customers:[],

	loggedIn:false,
	customersLoaded:false,

	items:[],
	suppliers:[],
	itemSuppliers:[],
	orders:[],
	orderRows:[],
	usage:[],
/*
	messages: [
		{timestamp:1,user:"",message:'Nomga Oy'},
		{timestamp:2,user:"",message:'Oy Firma Ab'},
		{timestamp:3,user:"",message:'Asiakas Oy'},
	],
	customers: [{timestamp:4,name:'Nomga Oy'},],
	productstable: [
		[{ value: "Tuote", readOnly:true }, { value: "Hinta", readOnly:true }, {value:"Määrä", readOnly:true}],
	  [{ value: "Tuntityö" }, { value: "55" }, {value:""}],
	  [{ value: "Tuote 1" }, { value: "25" }, {value:"100"}],
	  [{ value: "Tuote 2" }, { value: "10" }, {value:"55"}],
		[{ value: "Tuote 3" }, { value: "100" }, {value:"17"}],
	],
	worktimes:[
		...[...Array(5)].map((ob,index)=>{
		const start=new Date(new Date().setHours(7,Math.random()*30))
		const stop=new Date(new Date().setHours(15,30+Math.random()*30))
		return(
			{start:new Date(start.setDate(now.getDate()-(5-index))),stop:new Date(stop.setDate(now.getDate()-(5-index)))}
		)
	}),

	],
	invoicerows: [
		{timestamp: 5, item: "Tuote 1", quantity: 5, price: 25, alv: 24},
		{timestamp: 6, item: "Tuote 2", quantity: 3, price: 10, alv: 24},
		{timestamp: 7, item: "Tuntityö", quantity: 8, price: 55, alv: 24},
	],
	invoice: {
		customer:"Asiakas Oy",
		timestamp:new Date().getTime(),
		items:[],
	},

	productshistory: [
		{timestamp: new Date().getTime()-500000000, item: "Tuote 1", quantity: 5},
		{timestamp: new Date().getTime()-100000000, item: "Tuote 2", quantity: 3},
	],
	*/
};


const saveState = (state:ChatState) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('db', serializedState);
  } catch {
  }
}

export function chatReducer(
  state = initialState,
  action: ChatActionTypes
): ChatState {
	let retval=null;
  switch (action.type) {
		case DB_UPDATE:
			retval={
				...state,
				...action.payload,
			}
			break;

     case DB_INITIALIZE:
        return {
          ...initialState,
          ...action.state,
        }
/*
    case SEND_MESSAGE:
      retval= {
				...state,
        messages: [...state.messages, action.payload]
      };
			break;

		case UPDATE_MESSAGE:
			retval= {
				...state,
        messages: [...state.messages, action.payload]
			};
			break;

    case DELETE_MESSAGE:
      retval= {
				...state,
        messages: state.messages.filter(
          message => message.timestamp !== action.meta.timestamp
        )
      };
			break;
*/
    default:
  }

	if(retval) {
    saveState(retval)
    return retval
  }
  return state
}

