import React,{ useState, useEffect } from 'react';

import { connect } from "react-redux";
import { AppState } from "./store";

import { dbUpdate } from "./store/chat/actions";

import Card from './components/Card/Card';

import { ICard, ICustomer, ECardStatus } from './store/chat/types';

import { v4 as uuidv4 } from 'uuid';

import { Button } from 'reactstrap';

import axios from './axios';

import { model2ICard } from './InitScript';

interface IProps {
	cards: ICard[];
	customers: ICustomer[],
	dbUpdate: typeof dbUpdate;
}

const classes=require('./Main.module.scss');

const Main: React.FC<IProps> = ({
	cards,
	customers,
	dbUpdate,
}) => {

	const newCard = () => {
		//dbUpdate({cards:[...cards,

		const x:ICard={
			id:uuidv4(),
			header:"Uusi",
			details:"",
			amount:0,
			stars:1,
			time:new Date(),
			status:ECardStatus.NEW,
		}
		//]})
		
		updateCard(x,cards,-1)
	}

	const updateCard = (data:ICard,i:ICard[],index:number) => {
		const data2send:any={
			id:data.id,
			name:data.header,
			customer:data.customer && data.customer.id,
			attrs:{
				details:data.details,
				amount:data.amount,
				stars:data.stars,
				time:data.time,
				dueDate:data.dueDate,
				status:data.status,
				color:data.color,
			},
		}

		axios.post("getdata/cards/",{model:"cards",data:data2send}).then((resp)=>{
  	  if(resp.data.status==="auth") {
				dbUpdate({loggedIn:false});
  	  }
			else {
				const ob={attrs:{},...resp.data}
					/*
				const c:ICard={
					id:ob.id,
					header:ob.name,
					details:ob.attrs.details,
					amount:ob.attrs.amount,
					stars:ob.attrs.stars,
					time:ob.attrs.time,
					dueDate:ob.attrs.dueDate,
					status:ob.attrs.status,
					//						tasks:ob.attrs.tasks,
					color:ob.attrs.color,
				}
					*/
				const c=model2ICard(ob,customers);

				if(index!==-1) {
					i.splice(index,1);
				}
				i.push(c)
				dbUpdate({cards:[...i]})
  	  }
  	});
	}

	const current = (d:number) => {
		return (d).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')+"€";
	}


	const [sortedCards, setSortedCards]=useState<{[id:string]:any}>({
		[ECardStatus.NEW]:[],
		[ECardStatus.CONTACTED]:[],
		[ECardStatus.OFFER]:[],
		[ECardStatus.WON]:[],
	}) 

	useEffect(()=>{
		console.log("UPDATE");
		setSortedCards(cards.reduce((resp:{[id:string]:ICard[]},ob:ICard)=>{
			const os=ob.status || ECardStatus.NEW
			resp[os]=[...resp[os],ob]
		
			return resp
		},{
			[ECardStatus.NEW]:[],
			[ECardStatus.CONTACTED]:[],
			[ECardStatus.OFFER]:[],
			[ECardStatus.WON]:[],
		}))
	},[cards]);

	const calcTotal = (items:ICard[]) => {
		return (items || []).reduce((resp:number,ob:ICard)=>resp+ob.amount,0)
	}

	return (<div className={classes.pipeline}>

		<div className={classes.pipelinecol}>
			<div className={classes.header}>UUSI</div>
			<div className={classes.total}>{current(calcTotal(sortedCards[ECardStatus.NEW]))}</div>
			{sortedCards[ECardStatus.NEW].map((card:ICard)=>(<Card key={card.id} data={card}/>))}
		</div>

		<div className={classes.pipelinecol}>
			<div className={classes.header}>KONTAKTOITU</div>
			<div className={classes.total}>{current(calcTotal(sortedCards[ECardStatus.CONTACTED]))}</div>
			{sortedCards[ECardStatus.CONTACTED].map((card:ICard)=>(<Card key={card.id} data={card}/>))}
		</div>
		<div className={classes.pipelinecol}>
			<div className={classes.header}>TARJOUS</div>
			<div className={classes.total}>{current(calcTotal(sortedCards[ECardStatus.OFFER]))}</div>
			{sortedCards[ECardStatus.OFFER].map((card:ICard)=>(<Card key={card.id} data={card}/>))}
		</div>
		<div className={classes.pipelinecol}>
			<div className={classes.header}>VOITETTU</div>
			<div className={classes.total}>{current(calcTotal(sortedCards[ECardStatus.WON]))}</div>
			{sortedCards[ECardStatus.WON].map((card:ICard)=>(<Card key={card.id} data={card}/>))}
		</div>


		<div className={classes.bottombtn}>
			<Button color="primary" onClick={newCard}><i className="fas fa-plus"/> Uusi kortti</Button>
		</div>

		</div>);
}

const mapStateToProps = (state: AppState) => ({
	cards: state.chat.cards,
	customers: state.chat.customers,
});

export default connect(
  mapStateToProps,
  { dbUpdate }
)(Main);

