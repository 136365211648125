import React from 'react';

import { connect } from "react-redux";
import { AppState } from "../../store";


import { InputGroup, InputGroupAddon, InputGroupText, Col, Row, Input, Button, ButtonGroup } from 'reactstrap';

import { ITask, ETaskStatus, ETaskAction } from '../../store/chat/types';

import { newDate } from '../../newDate';
import { dbUpdate } from "../../store/chat/actions";

import DatePicker from 'react-datepicker';

const classes=require('./Card.module.scss');

interface IProps {
	task:ITask;
	update:(task:ITask)=>void;
	close:()=>void;
}

const EditTask: React.FC<IProps> = ({
	task,
	update,
	close,
}) => {

	const statusIcons:{[id:string]:string}={
		[ETaskStatus.TODO]:"far fa-circle "+classes.TODO,
		[ETaskStatus.DONE]:"fas fa-circle "+classes.DONE,
		[ETaskStatus.DONT]:"fas fa-circle "+classes.DONT,
		[ETaskStatus.DELETED]:"fas fa-circle "+classes.DELETED,

	}

	const actionIcons:{[id:string]:string}={
		[ETaskAction.TASK]:"fas fa-tasks",
		[ETaskAction.PHONE]:"fas fa-phone",
		[ETaskAction.MEET]:"fas fa-handshake",
		[ETaskAction.EMAIL]:"fas fa-envelope",
	}

	const deleteTask = () => {
		update({...task,status:ETaskStatus.DELETED})
		close();
	}

	return !task?null:(<div className={classes.edittask+" text-center"}>
		<ButtonGroup className="w-100">
			{Object.keys(statusIcons).filter((ob)=>ob!=="DELETED").map((ob,i)=>(
				<Button key={i} onClick={()=>update({...task,status:ob as ETaskStatus})} color={ob===task.status?"primary":"secondary"}><i className={statusIcons[ob]}/></Button>
			))}
		</ButtonGroup>

		<DatePicker
			inline
			showTimeSelect
			showWeekNumbers
			dateFormat="dd.MM.yyyy HH:mm"
			timeFormat="HH:mm"
			selected={newDate(task.time)}
			onChange={date => update({...task,time:newDate(date)})}
		/>
		{/*	<div><i className="fas fa-phone"/></div>*/}

		<ButtonGroup className="w-100">
			{Object.keys(actionIcons).map((ob,i)=>(
				<Button key={i} onClick={()=>update({...task,action:ob as ETaskAction})} color={ob===task.action?"primary":"secondary"}><i className={actionIcons[ob]}/></Button>
			))}
		</ButtonGroup>

		{task.action===ETaskAction.PHONE && <div>
			<InputGroup>
				<InputGroupAddon addonType="prepend" >
					<InputGroupText><i className="fas fa-user"/></InputGroupText>
				</InputGroupAddon>
				<Input type="text" value={task.contact} onChange={(e)=>update({...task,contact:e.target.value})} placeholder="Yhteyshenkilö"/>
			</InputGroup>

			<InputGroup>
				<InputGroupAddon addonType="prepend" >
					<InputGroupText><i className="fas fa-phone"/></InputGroupText>
				</InputGroupAddon>
				<Input type="text" value={task.phone} onChange={(e)=>update({...task,phone:e.target.value})} placeholder="Puhelinnumero"/>
			</InputGroup>
		</div>}

		{task.action===ETaskAction.EMAIL && <div>
			<InputGroup>
				<InputGroupAddon addonType="prepend" >
					<InputGroupText><i className="fas fa-user"/></InputGroupText>
				</InputGroupAddon>
				<Input type="text" value={task.contact} onChange={(e)=>update({...task,contact:e.target.value})} placeholder="Yhteyshenkilö"/>
			</InputGroup>

			<InputGroup>
				<InputGroupAddon addonType="prepend" >
					<InputGroupText><i className="fas fa-envelope"/></InputGroupText>
				</InputGroupAddon>
				<Input type="text" value={task.email} onChange={(e)=>update({...task,email:e.target.value})} placeholder="Sähköposti"/>
			</InputGroup>
		</div>}

		{task.action===ETaskAction.MEET && <div>
			<InputGroup>
				<InputGroupAddon addonType="prepend" >
					<InputGroupText><i className="fas fa-user"/></InputGroupText>
				</InputGroupAddon>
				<Input type="text" value={task.contact} onChange={(e)=>update({...task,contact:e.target.value})} placeholder="Yhteyshenkilö"/>
			</InputGroup>

			<InputGroup>
				<InputGroupAddon addonType="prepend" >
					<InputGroupText><i className="fas fa-map-marker"/></InputGroupText>
				</InputGroupAddon>
				<Input type="text" value={task.place} onChange={(e)=>update({...task,place:e.target.value})} placeholder="Paikka"/>
			</InputGroup>
		</div>}



		<Input type="textarea" value={task.description} rows={5} onChange={(e)=>update({...task,description:e.target.value})} placeholder="Tehtävä..."/>

		<br/>
		<Row>
			<Col><Button color="danger" block onClick={deleteTask}>Poista tehtävä</Button></Col>

			<Col>
			</Col>

			<Col><Button color="primary" block onClick={close}>Pienennä</Button></Col>
		</Row>
	</div>);
}

const mapStateToProps = (state: AppState) => ({
	cards: state.chat.cards,
});

export default connect(
  mapStateToProps,
	{ dbUpdate }
)(EditTask);

