import React, { useState } from 'react';

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const classes=require('./Colors.module.scss');

interface IProps {
	update:(color:string)=>void;
	color?:string;
}

const Colors: React.FC<IProps> = ({
	color,
	update,
}) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);

	const toggle = () => setDropdownOpen(prevState => !prevState);

	const setColor = (col:string) => {
		update(col);
	}

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
			<DropdownToggle className={classes[color || "color1"]} style={{"color":"#000"}}>
				<i className="fas fa-tint"/>
        </DropdownToggle>
      <DropdownMenu>
				<DropdownItem onClick={()=>setColor("color1")} className={classes.color1}><i className="fas fa-tint"/></DropdownItem>
				<DropdownItem onClick={()=>setColor("color2")} className={classes.color2}><i className="fas fa-tint"/></DropdownItem>
				<DropdownItem onClick={()=>setColor("color3")} className={classes.color3}><i className="fas fa-tint"/></DropdownItem>
				<DropdownItem onClick={()=>setColor("color4")} className={classes.color4}><i className="fas fa-tint"/></DropdownItem>
				<DropdownItem onClick={()=>setColor("color5")} className={classes.color5}><i className="fas fa-tint"/></DropdownItem>
				<DropdownItem onClick={()=>setColor("color6")} className={classes.color6}><i className="fas fa-tint"/></DropdownItem>
				<DropdownItem onClick={()=>setColor("color7")} className={classes.color7}><i className="fas fa-tint"/></DropdownItem>
				<DropdownItem onClick={()=>setColor("color8")} className={classes.color8}><i className="fas fa-tint"/></DropdownItem>
				<DropdownItem onClick={()=>setColor("color9")} className={classes.color9}><i className="fas fa-tint"/></DropdownItem>
				<DropdownItem onClick={()=>setColor("color10")} className={classes.color10}><i className="fas fa-tint"/></DropdownItem>


      </DropdownMenu>
    </Dropdown>
  );

}

export default Colors;

