import React from 'react';

const classes=require('./Stars.module.scss');

interface IProps {
	total:number;
	stars:number;
	onClick?:(i:number)=>void;
}

const Stars: React.FC<IProps> = ({
	total,
	stars,
	onClick,
}) => {

	const data=[...Array(total).keys()].map((ob,index)=>index<stars?"fas":"far");

	return (<div>
		{data.map((ob,index)=>(
			<i key={index} className={classes[ob]+" "+ob+" fa-star"} onClick={()=>{if(onClick) onClick(index+1)}}/>))}
		</div>
  );
}

export default Stars;

