import React from 'react';

import { connect } from "react-redux";
import { AppState } from "./store";

import { dbUpdate } from "./store/chat/actions";

import Task from './components/Task/Task';

import { ICard, ITask, ETaskStatus } from './store/chat/types';


import { newDate } from './newDate';

interface IProps {
	cards: ICard[];
	dbUpdate: typeof dbUpdate;
}

//const classes=require('./Main.module.scss');

const Main: React.FC<IProps> = ({
	cards,
	dbUpdate,
}) => {


	const tasks=cards.reduce((resp:{task:ITask,card:ICard}[],ob)=>{
		if(ob.tasks) {
			const t=ob.tasks.filter((ob)=>ob.status===ETaskStatus.TODO).map((o)=>({task:o,card:ob}))
			resp=[...resp,...t]
		}
		return resp
	},[]).sort((a:{task:ITask,card:ICard},b:{task:ITask,card:ICard})=>newDate(a.task.time)<newDate(b.task.time)?-1:1)


	return (<div className="taskview">
		{tasks.map((ob,i)=>{
			const { task, card }=ob
	
			return(<div key={i}>
				<Task card={card} task={task}/>
			</div>)
		})}

	</div>);
}

const mapStateToProps = (state: AppState) => ({
	cards: state.chat.cards,
});

export default connect(
  mapStateToProps,
  { dbUpdate }
)(Main);

