export {}

declare global {
	interface Date {
		printdate(): string;
		printtime(): string;
	}
}

//eslint-disable-next-line no-extend-native
Date.prototype.printdate = function() {
			if(isNaN(this.getTime())) { return "" }

		  var date = new Date(this.getTime());
			let day = "00"+date.getDate();
	  	let month = "00"+(date.getMonth()+1);
		  let year = date.getFullYear();

			return day.substring(day.length-2)+"."+month.substring(month.length-2)+"."+year
			
		}

//eslint-disable-next-line no-extend-native
Date.prototype.printtime = function() {
			if(isNaN(this.getTime())) { return "" }

		  var date = new Date(this.getTime());
			let h = "00"+date.getHours();
	  	let m = "00"+(date.getMinutes());

			return h.substring(h.length-2)+":"+m.substring(m.length-2)
			
		}
