import React from 'react';

import { connect } from "react-redux";
import { AppState } from "./store";

import { dbUpdate } from "./store/chat/actions";

import Customer from './components/Customer/Customer';

import { ICard, ICustomer } from './store/chat/types';

import { Button } from 'reactstrap';

import { v4 as uuidv4 } from 'uuid';

import axios from './axios';

const classes=require('./Main.module.scss');
//const classes=require('./Customers.module.scss');


interface IProps {
	cards: ICard[];
	customers: ICustomer[];
	dbUpdate: typeof dbUpdate;
	customersLoaded:boolean;
}

const Customers: React.FC<IProps> = ({
	cards,
	customers,
	dbUpdate,
	customersLoaded,
}) => {
	
	const loadCustomers = () => {
		axios.get("getdata/customers1/?model=customers1").then((resp)=>{
  	  if(resp.data.status==="auth") {
				dbUpdate({loggedIn:false});
  	  }
			else {
				const overload:string[]=[]
				const tc:ICustomer[]=[]
				Object.values(resp.data).map((ob:any)=>{				
					const c:ICustomer={
						id:ob.id,
						erp_id:ob.attrs.erp_id,
						name:ob.name,
						no:ob.attrs.no,
						street:ob.attrs.street,
						zipcode:ob.attrs.zipcode,
						city:ob.attrs.city,
						contact:ob.attrs.contact,
						phone:ob.attrs.phone,
						email:ob.attrs.email,
					}
					tc.push(c)
					if(ob.attrs.erp_id) { overload.push(ob.attrs.erp_id) }
				})
				//dbUpdate({customers:tc})

				loadCustomers2(tc,overload)
  	  }
  	});
	}


	const loadCustomers2 = (preload:ICustomer[],overload:string[]) => {
		console.log(overload)
		axios.get("getdata?model=customers2").then((resp)=>{
  	  if(resp.data.status==="auth") {
				dbUpdate({loggedIn:false});
  	  }
			else {
				const tc:ICustomer[]=preload
				Object.values(resp.data).map((ob:any)=>{
					if(!overload.includes(ob.id)) {
						const c:ICustomer={
							id:ob.id,
							erp_id:ob.id,
							iserp:true,
							name:ob.name,
							no:ob.attrs.customerid,
						}
						tc.push(c)
					}
				})
				
				dbUpdate({customers:tc})

  	  }
  	});
	}

	if(!customersLoaded) {
		dbUpdate({customersLoaded:true});
		loadCustomers();
	}

	const newCard = () => {
		dbUpdate({customers:[...customers,{
			id:uuidv4(),
			name:"Uusi asiakas",
		}]})
	}

	const customersSort = (a:ICustomer, b:ICustomer) => {
		const an:string=a.name || ""
		const bn:string=b.name || ""
		return(
			an.toLowerCase()<bn.toLowerCase()?-1:1
		)
	}

	return (<div className="customerview">
		{customers.sort(customersSort).map((ob,i)=>{
			return(<div key={i}>
				<Customer customer={ob}/>
			</div>)
		})}


		<div className={classes.bottombtn}>
			<Button color="primary" onClick={newCard}><i className="fas fa-plus"/> Uusi asiakas</Button>
			{' '}<Button color="secondary" onClick={loadCustomers}>Päivitä</Button>

		</div>


	</div>);
}

const mapStateToProps = (state: AppState) => ({
	cards: state.chat.cards,
	customers: state.chat.customers,
	customersLoaded: state.chat.customersLoaded,
});

export default connect(
  mapStateToProps,
  { dbUpdate }
)(Customers);

