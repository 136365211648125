import React from 'react';

import { useLocation } from 'react-router-dom';

import { LocalizedLink } from './i18n/LocalizedLink';

import { AppRoute } from './i18n/const';

import { useIntl } from 'react-intl';

import { Button } from 'reactstrap';

//const classes=require('./MenuBar.module.scss');

interface IProps {
}

const MenuBar: React.FC<IProps> = (props: IProps) => {
	const { locale, messages } = useIntl();
	const { pathname } = useLocation();

  const [, route] = pathname.split(locale);
  const routeKey = Object.keys(messages).find(key => messages[key] === route);

	const logout = () => {
		let cookies = document.cookie;

		for (let i = 0; i < cookies.split(";").length; ++i)
		{
		    let myCookie = cookies[i];
		    let pos = myCookie.indexOf("=");
		    let name = pos > -1 ? myCookie.substr(0, pos) : myCookie;
		    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
		}

		sessionStorage.clear();
		localStorage.clear();

		window.location.reload();
	}

	return (
		<div className="topmenu">
			<LocalizedLink to={AppRoute.Home}>
				<div className={routeKey===AppRoute.Home?"active":""}>
					<i className="fas fa-table"/><span> FUNNEL</span>
				</div>
			</LocalizedLink>

			<LocalizedLink to={AppRoute.Todo}>
				<div className={routeKey===AppRoute.Todo?"active":""}>
					<i className="fas fa-clipboard-list"/><span> TODO</span>
				</div>
			</LocalizedLink>

			<LocalizedLink to={AppRoute.Customers}>
				<div className={routeKey===AppRoute.Customers?"active":""}>
					<i className="fas fa-users"/><span> ASIAKKAAT</span>
				</div>
			</LocalizedLink>
			{/*
			<LocalizedLink to={AppRoute.Contacts}>
				<div className={routeKey===AppRoute.Contacts?"active":""}>
					<i className="fas fa-address-book"/><span> CONTACTS</span>
				</div>
			</LocalizedLink>
				*/}

			<Button color="link" onClick={logout}><i className="fas fa-sign-out-alt"/></Button>
	</div>)
}

export default MenuBar;
